import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Avatar, { AvatarStyle } from 'avataaars';
import { Name, TeamGrid, TeamMember } from './team';
export const avatarStyle = {
  width: 90,
  height: 90
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Role = makeShortcode("Role");
const Callsign = makeShortcode("Callsign");
const layoutProps = {
  avatarStyle,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Meet the team`}</h2>
    <TeamGrid mdxType="TeamGrid">
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle={AvatarStyle.Circle} topType="ShortHairTheCaesar" accessoriesType="Blank" hairColor="Blonde" facialHairType="BeardMedium" facialHairColor="Blonde" clotheType="BlazerShirt" eyeType="Surprised" eyebrowType="RaisedExcited" mouthType="Default" skinColor="Light" mdxType="Avatar" />
    <Name mdxType="Name">Matias Piipari</Name>
    <Role mdxType="Role">
      Senior Product Director
      <br />
      (Chief Moog Operator)
    </Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://twitter.com/mz2" rel={'noopener noreferrer'}>
        @mz2
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="LongHairBun" accessoriesType="Blank" hairColor="BrownDark" facialHairType="Blank" clotheType="BlazerShirt" eyeType="Squint" eyebrowType="DefaultNatural" mouthType="Default" skinColor="Tanned" mdxType="Avatar" />
    <Name mdxType="Name">Alberto Pepe</Name>
    <Role mdxType="Role">
      Product Director
      <br />
      (Spiritual Warrior)
    </Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://twitter.com/albertopepe" rel={'noopener noreferrer'}>
        @albertopepe
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="ShortHairDreads01" accessoriesType="Blank" hairColor="Blonde" facialHairType="BeardLight" facialHairColor="Blonde" clotheType="ShirtVNeck" eyeType="Wink" eyebrowType="RaisedExcited" mouthType="Serious" skinColor="Light" mdxType="Avatar" />
    <Name mdxType="Name">Alf Eaton</Name>
    <Role mdxType="Role">Senior Software Engineer</Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://twitter.com/invisiblecomma" rel={'noopener noreferrer'}>
        @invisiblecomma
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="Hijab" accessoriesType="Blank" clotheType="CollarSweater" clotheColor="Gray01" eyeType="Happy" eyebrowType="DefaultNatural" mouthType="Smile" skinColor="Light" mdxType="Avatar" />
    <Name mdxType="Name">Islam Momani</Name>
    <Role mdxType="Role">Software Engineer</Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://gitlab.com/Islamjmomani" rel={'noopener noreferrer'}>
        @IslamJMomani
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="ShortHairShortCurly" accessoriesType="Blank" hairColor="Brown" facialHairType="Blank" clotheType="ShirtCrewNeck" clotheColor="PastelGreen" eyeType="Side" eyebrowType="UnibrowNatural" mouthType="Smile" skinColor="Brown" mdxType="Avatar" />
    <Name mdxType="Name">Will Bartlett</Name>
    <Role mdxType="Role">Software Engineer</Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://github.com/will118" rel={'noopener noreferrer'}>
        @will118
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="ShortHairShortRound" accessoriesType="Blank" hairColor="Black" facialHairType="BeardLight" facialHairColor="Black" clotheType="Hoodie" clotheColor="Black" eyeType="Hearts" eyebrowType="DefaultNatural" mouthType="Smile" skinColor="Light" mdxType="Avatar" />
    <Name mdxType="Name">Bader Al-Hamdan</Name>
    <Role mdxType="Role">Software Engineer</Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://gitlab.com/BaderAlhamdan" rel={'noopener noreferrer'}>
        @baderalhamdan
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="ShortHairShortFlat" accessoriesType="Blank" hairColor="Blonde" facialHairType="BeardLight" facialHairColor="Blonde" clotheType="Hoodie" clotheColor="Heather" eyeType="Happy" eyebrowType="DefaultNatural" mouthType="Default" skinColor="Pale" mdxType="Avatar" />
    <Name mdxType="Name">Dan Browne</Name>
    <Role mdxType="Role">Software Engineer</Role>
    <Callsign mdxType="Callsign">
      <a target="_blank" href="https://github.com/danielrbrowne" rel={'noopener noreferrer'}>
        @danielrbrowne
      </a>
    </Callsign>
  </TeamMember>
  <TeamMember mdxType="TeamMember">
    <Avatar style={avatarStyle} avatarStyle="Circle" topType="ShortHairShortWaved" accessoriesType="Blank" hairColor="Brown" facialHairType="BeardLight" facialHairColor="Brown" clotheType="CollarSweater" clotheColor="Heather" eyeType="Wink" eyebrowType="FlatNatural" mouthType="Default" skinColor="Light" mdxType="Avatar" />
    <Name mdxType="Name">Yannis Saniotis</Name>
    <Role mdxType="Role">Director of Product Design</Role>
  </TeamMember>
    </TeamGrid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      