import styled from 'styled-components'

export const Name = styled.div`
  font-weight: bold;
`

export const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 30px;
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
`
export const TeamMember = styled.div`
  font-family: Barlow, sans-serif;
  text-align: center;
  width: 260px;
`
