import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Word processing `}<em parentName="h2">{`designed`}</em>{`, end to end for the actual needs of a scientist: interoperable data driven documents on the web, no baggage, no lock-in.`}</h2>
    <ProjectCard title="Embeddable Document Editor / Viewer" link="https://gitlab.com/mpapp-public/manuscripts-json-schema" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
   A beautiful, embeddable component for editing scholarly documents, built with <b>React</b> and <b>ProseMirror</b>.
    </ProjectCard>
    <ProjectCard title="Document Conversions" link="https://gitlab.com/mpapp-public/pressroom" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
  <b>Pressroom</b> is a document and bibliography format conversion service for <b>Markdown, LaTeX, HTML, JATS XML</b> + more.
    </ProjectCard>
    <ProjectCard title="Collaborative, Offline proof Syncing" link="https://gitlab.com/mpapp-public/manuscripts-sync" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
   Lock-in free, integration friendly realtime document synchronisation backend for web and native app backed by the open source <b>Couchbase Server</b> and <b>Sync Gateway</b>.
    </ProjectCard>
    <ProjectCard title="Metadata Rich Documents" link="https://gitlab.com/mpapp-public/manuscripts-json-schema" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
  A semantically rich document schema for document front matter and body with rich semantics for tracking authorship, citations and computational experiments + documentation and utilies tools for wide interoperability widely (JATS XML, DOCX, LaTeX, Jupyter and more).
    </ProjectCard>
    <ProjectCard title="Role based access control" link="https://gitlab.com/mpapp-public/manuscripts-json-schema" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
  Cloud and data centre hostable backend services.
    </ProjectCard>
    <ProjectCard title="Browser based and installable" link="https://gitlab.com/mpapp-public/manuscripts-cocoa-app" bg="linear-gradient(to top, #2C8BFF 0%, #64CCFF 100%)" mdxType="ProjectCard">
  Work in the browser, or in full privacy with a locally installable app and files you control yourself.
    </ProjectCard>
    <a href="https://gitlab.com/mpapp-public">Learn more</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      