import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Manuscripts.io is a next generation writing tool to follow up Manuscripts.app (Manuscripts for Mac) re-engineered to support collaboration, whilst still allowing for a fully offline workflow, with both web and native desktop app client applications in the works (with beta version of the web client available).`}</p>
    <p>{`We are building Manuscripts openly to forge a community. Research is increasingly free and open to build on – tools for research should be, too.`}</p>
    <p>{`Manuscripts is modular: it is a product made of a series of separable components that can also be used to extend or create other systems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      